import { Navigate, Outlet } from "react-router";
import { useSearchParams } from "react-router-dom";
import LogoutUser from "./Logout";

const useAuth = () => {
  const user = JSON.parse(localStorage.getItem("user_profile"));

  if (user !== null) {
    return user;
  }
};

const ProtectedRoutes = () => {
  const isAuth = useAuth();

  //This block of code checks if a person is coming from ucard.store after creating an agent account.
  //If the person is coming from ucard.store, it logs out the present account and completely clears session.
  // eslint-disable-next-line no-unused-vars
  const [search, setSearch] = useSearchParams();

  if (search.get("from") === "parent") {
    LogoutUser();
    return "Loading...";
  }

  // const isAuth = accessToken && role === "5";

  return isAuth ? <Outlet /> : <Navigate to={"/"} />;
};

const LoggedInRoute = () => {
  const isAuth = useAuth();

  return !isAuth ? <Outlet /> : <Navigate to={"/dashboard"} />;
};

export { ProtectedRoutes, LoggedInRoute };
