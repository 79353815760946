import api from "../api";

const ReportsModule = () => {
  return {
    ListTransaction: function (data) {
      return api.post("wallet/Wallet/walletwithdownlinetransactions", data);
    },

    AgentListTransaction: function (data) {
      return api.post("wallet/Wallet/downline/wallettransactions", data);
    },

    TransactionDetails: function (data) {
      return api.post(`/Admin/reports/airtime/${data?.ref}`, data);
    },

    MainWallet: function (data) {
      return api.post("wallet/Wallet/walletmovements", data);
    },

    CommissionWalletReport: function (data) {
      ////console.log("data new stuff", data);
      return api.get(
        `/wallet/Wallet/commissionmovements?PageSize=${data.pageSize}&CurrentPage=${data.currentPage}&keyword=${data.keyword}&ServiceType=${data.ServiceType}&StartDate=${data.startDate}&EndDate=${data.endDate}`
      );
    },

    AirtimeReports: function (data) {
      ////console.log("data new stuff", data);
      return api.get(
        // `/wallet/Wallet/commissionmovements?PageSize=${data.pageSize}&CurrentPage=${data.currentPage}&keyword=${data.keyword}&ServiceType=${data.ServiceType}&StartDate=${data.startDate}&EndDate=${data.endDate}`
        `airtime/Airtime/airtimetransactions?PageSize=${data.pageSize}&CurrentPage=${data.currentPage}&SearchText=${data.keyword}&Network=${data.network}&StartDate=${data.startDate}&EndDate=${data.endDate}&TransactionStatus=${data.transactionStatus}`
        // ?SearchText=""&TransactionStatus=2&Network=2&PageSize=10&CurrentPage=1&CurrentPageIndex=1&StartDate=2021-01-01&EndDate=2022-09-30
        // `
      );
    },

    BillsReports: function (data) {
      ////console.log("data new stuff", data); &includeDownline=true
      return api.get(
        `bills/BillerService/billstransactions?PageSize=${data.pageSize}&CurrentPage=${data.currentPage}&SearchText=${data.keyword}&Network=${data.network}&StartDate=${data.startDate}&EndDate=${data.endDate}&TransactionStatus=${data.transactionStatus}`
      );
    },

    DataReports: function (data) {
      return api.get(
        `data/Service/datatransactions?PageSize=${data.pageSize}&CurrentPage=${data.currentPage}&SearchText=${data.keyword}&Network=${data.network}&StartDate=${data.startDate}&EndDate=${data.endDate}&TransactionStatus=${data.transactionStatus}`
      );
    },

    CusstomerListing: function (data) {
      //console.log("data new stuff", data);
      return api.get(
        `identity/Agent/customerlisting?AgentRoleId=${data.agentrole}&PageSize=${data.pageSize}&CurrentPage=${data.currentPage}&StartDate=${data.startDate}&EndDate=${data.endDate}`
      );
    },

    SingleCustomerListing: function (data) {
      return api.get(`identity/Agent/downline/details?walletId=${data}`);
    },

    TransactionSummary: function (data) {
      // ////console.log("data2", data);
      const { startDate, endDate, service_code, currentPage, pageSize } = data;
      return api.get(
        `wallet/Wallet/report/service/summary?startDate=${startDate}&endDate=${endDate}&service_code=${service_code}&CurrentPage=${currentPage}&PageSize=${pageSize}`
      );
    },
  };
};

export const ReportsService = {
  reportsModule: ReportsModule,
};
