import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SettingService } from "./SettingsService";
import { toast } from "react-toastify";

export const Onboarding = createAsyncThunk(
  "ls/Onboarding",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await SettingService.settingsModule().Onboarding(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const ChangePin = createAsyncThunk(
  "ls/ChangePin",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await SettingService.settingsModule().ChangePin(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const SetNewPin = createAsyncThunk(
  "ls/SetNewPin",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await SettingService.settingsModule().CreatePin(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const SetPin = createAsyncThunk(
  "ls/ChangePin",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await SettingService.settingsModule().SetPin(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const ChangePassword = createAsyncThunk(
  "ls/ChangePassword",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await SettingService.settingsModule().ChangePassword(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const ChangeTransactionPin = createAsyncThunk(
  "ls/ChangeTransactionPin",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await SettingService.settingsModule().ChangeTransactionPin(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const OTPByEmail = createAsyncThunk(
  "ls/OTPByEmail",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await SettingService.SettingsModule().GetOTPByEmail(
        payload
      );
      return response?.data;
    } catch (error) {
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const ConfirmPassword = createAsyncThunk(
  "ls/ConfirmPassword",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await SettingService.SettingsModule().ConfirmPassword(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const AddUserBankAccount = createAsyncThunk(
  "ls/AddUserBankAccount",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await SettingService.settingsModule().AddBankAccount(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const ChangeUserBankAccount = createAsyncThunk(
  "ls/ChangeUserBankAccount",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await SettingService.settingsModule().ChangeBankAccount(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const GetBanks = createAsyncThunk(
  "ls/GetBanks",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await SettingService.settingsModule().GetBanks(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const GetCoralPayBanks = createAsyncThunk(
  "ls/GetBanks",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await SettingService.settingsModule().GetCoralBanks(
        payload
      );
      return response?.data;
    } catch (error) {
      !error?.response?.data?.message &&
        toast.error("An Error Occured Will Completing Action...");
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const CoralString = createAsyncThunk(
  "ls/CoralString",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await SettingService.settingsModule().GetFormCoralString(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const TransferToBank = createAsyncThunk(
  "ls/TransferToBank",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await SettingService.settingsModule().SendMoney(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const TransferToWallet = createAsyncThunk(
  "ls/TransferToWallet",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await SettingService.settingsModule().TransferToWallet(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const VerifyPassword = createAsyncThunk(
  "ls/VerifyPassword",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await SettingService.settingsModule().VerifyPassword(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const ValidateOTP = createAsyncThunk(
  "ls/VerifyPassword",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await SettingService.settingsModule().VerifyOTP(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const ValidateAuthOTP = createAsyncThunk(
  "ls/ValidatePassword",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await SettingService.settingsModule().ValidAuthOTP(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const SendOTP = createAsyncThunk(
  "ls/SendOTP",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await SettingService.settingsModule().SendOTP(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const ResendOTP = createAsyncThunk(
  "ls/SendOTP",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await SettingService.settingsModule().ResendOTP(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const VerifyBankAccount = createAsyncThunk(
  "ls/VerifyBankAccount",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await SettingService.settingsModule().VerifyBankAccount(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const VerifyWalletId = createAsyncThunk(
  "ls/VerifyWalletId",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await SettingService.settingsModule().VerifyWalletId(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const AddContactPerson = createAsyncThunk(
  "ls/AddContactPerson",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await SettingService.settingsModule().AddContactPerson(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const UploadAgentImage = createAsyncThunk(
  "ls/AddContactPerson",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await SettingService.settingsModule().UploadAgentImage(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const CreateWallet = createAsyncThunk(
  "ls/AddContactPerson",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await SettingService.settingsModule().CreateWallet(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const UpdatePersonalInformation = createAsyncThunk(
  "ls/AddContactPerson",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await SettingService.settingsModule().UpdatePersonalInformation(
          payload
        );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const SettingSlice = createSlice({
  name: "Setting",
  initialState: "",
  extraReducers: {
    [SetNewPin.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [SetNewPin.fulfilled]: (state, action) => {
      return { ...state, setnewpin: action.payload, loading: false };
    },
    [SetNewPin.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    [Onboarding.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [Onboarding.fulfilled]: (state, action) => {
      return { ...state, onboarding: action.payload, loading: false };
    },
    [Onboarding.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ChangePin.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [ChangePin.fulfilled]: (state, action) => {
      return { ...state, changePin: action.payload, loading: false };
    },
    [ChangePin.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ChangePassword.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [ChangePassword.fulfilled]: (state, action) => {
      return { ...state, changePassword: action.payload, loading: false };
    },
    [ChangePassword.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ChangeTransactionPin.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [ChangeTransactionPin.fulfilled]: (state, action) => {
      return { ...state, changeTransactionPin: action.payload, loading: false };
    },
    [ChangeTransactionPin.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    // [ChangeTransactionPin.pending]: (state) => {
    //   state.loading = true;
    // },
    // [ChangeTransactionPin.fulfilled]: (state, action) => {
    //   return { ...state, changeTransactionPin: action.payload, loading: false };
    // },
    // [ChangeTransactionPin.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload;
    // },

    [OTPByEmail.pending]: (state) => {
      state.loading = true;
    },
    [OTPByEmail.fulfilled]: (state, action) => {
      return { ...state, otpByEmail: action.payload, loading: false };
    },
    [OTPByEmail.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [ConfirmPassword.pending]: (state) => {
      state.loading = true;
    },
    [ConfirmPassword.fulfilled]: (state, action) => {
      return { ...state, confirmPassword: action.payload, loading: false };
    },
    [ConfirmPassword.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [GetBanks.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [GetBanks.fulfilled]: (state, action) => {
      return { ...state, banks: action.payload, loading: false };
    },
    [GetBanks.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [CoralString.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [CoralString.fulfilled]: (state, action) => {
      return { ...state, coral: action.payload, loading: false };
    },
    [CoralString.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ChangeUserBankAccount.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [ChangeUserBankAccount.fulfilled]: (state, action) => {
      return { ...state, changeBankAccount: action.payload, loading: false };
    },
    [ChangeUserBankAccount.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [AddUserBankAccount.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [AddUserBankAccount.fulfilled]: (state, action) => {
      return { ...state, bankAccount: action.payload, loading: false };
    },
    [AddUserBankAccount.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [SendOTP.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [SendOTP.fulfilled]: (state, action) => {
      return { ...state, sendOTP: action.payload, loading: false };
    },
    [SendOTP.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [TransferToBank.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [TransferToBank.fulfilled]: (state, action) => {
      return { ...state, transfer: action.payload, loading: false };
    },
    [TransferToBank.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [VerifyPassword.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [VerifyPassword.fulfilled]: (state, action) => {
      return { ...state, verifiedPassword: action.payload, loading: false };
    },
    [VerifyPassword.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [VerifyBankAccount.pending]: (state) => {
      return {
        ...state,
        loading: true,
        verifying: true,
      };
    },
    [VerifyBankAccount.fulfilled]: (state, action) => {
      return {
        ...state,
        verifiedBank: action.payload,
        loading: false,
        verifying: false,
      };
    },
    [VerifyBankAccount.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        verifying: false,
        error: action.payload,
      };
    },

    //VerifyWalletId
    [VerifyWalletId.pending]: (state) => {
      return {
        ...state,
        loading: true,
        verifying: true,
      };
    },
    [VerifyWalletId.fulfilled]: (state, action) => {
      return {
        ...state,
        verifiedWallet: action.payload,
        loading: false,
        verifying: false,
      };
    },
    [VerifyWalletId.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        verifying: false,
        error: action.payload,
      };
    },

    [AddContactPerson.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [AddContactPerson.fulfilled]: (state, action) => {
      return { ...state, contactPerson: action.payload, loading: false };
    },
    [AddContactPerson.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
  },
});

export default SettingSlice.reducer;
