import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { InvoiceService } from "./InvoiceService";
import { toast } from "react-toastify";

export const GetInvoiceDetails = createAsyncThunk(
  "ls/GetInvoiceDetails",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await InvoiceService.invoiceModule().GetOpenInvoiceDetails(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.message);

      return error?.response;
    }
  }
);

export const GetInvoiceTnxHistory = createAsyncThunk(
  "ls/GetInvoiceTnxHistory",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await InvoiceService.invoiceModule().GetOpenInvoiceTxn(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.message);
      return error?.response;
    }
  }
);

export const GetInvoiceList = createAsyncThunk(
  "ls/GetInvoiceList",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await InvoiceService.invoiceModule().GetInvoiceList(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.message);

      return error?.response;
    }
  }
);

export const MakeInvoicePayment = createAsyncThunk(
  "ls/MakeInvoicePayment",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await InvoiceService.invoiceModule().MakeInvoicePayment(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);

      return error?.response;
    }
  }
);

export const InvoiceNotification = createAsyncThunk(
  "ls/InvoiceNotification",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await InvoiceService.invoiceModule().InvoiceNotification(
        payload
      );
      return response?.data?.data?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);

      return error?.response;
    }
  }
);



export const MarkInvoiceNotification = createAsyncThunk(
  "ls/MarkInvoiceNotification",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await InvoiceService.invoiceModule().MarkInvoiceNotification(
        payload
      );

      console.log(response);
      return response?.data?.data?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);

      return error?.response;
    }
  }
);

export const InvoiceSlice = createSlice({
  name: "Invoice",
  initialState: "",
  extraReducers: {
    [GetInvoiceDetails.pending]: (state) => {
      return {
        ...state,
        loadingDetails: true,
      };
    },
    [GetInvoiceDetails.fulfilled]: (state, action) => {
      return {
        ...state,
        invoice_details: action.payload,
        loadingDetails: false,
      };
    },
    [GetInvoiceDetails.rejected]: (state, action) => {
      return {
        ...state,
        loadingDetails: false,
        error: action.payload,
      };
    },

    [GetInvoiceTnxHistory.pending]: (state) => {
      return {
        ...state,
        loadingTnx: true,
      };
    },
    [GetInvoiceTnxHistory.fulfilled]: (state, action) => {
      return {
        ...state,
        invoice_tnx: action.payload,
        loadingTnx: false,
      };
    },
    [GetInvoiceTnxHistory.rejected]: (state, action) => {
      return {
        ...state,
        loadingTnx: false,
        error: action.payload,
      };
    },

    [GetInvoiceList.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [GetInvoiceList.fulfilled]: (state, action) => {
      return {
        ...state,
        invoice_list: action.payload,
        loading: false,
      };
    },
    [GetInvoiceList.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [InvoiceNotification.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [InvoiceNotification.fulfilled]: (state, action) => {
      return {
        ...state,
        notification: action.payload,
        loading: false,
      };
    },
    [InvoiceNotification.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [MakeInvoicePayment.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [MakeInvoicePayment.fulfilled]: (state, action) => {
      return {
        ...state,
        payment: action.payload,
        loading: false,
      };
    },
    [MakeInvoicePayment.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
  },
});

export default InvoiceSlice.reducer;
