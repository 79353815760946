import React from "react";
import "./SuccessDisplay.css";
import SuccessGIF from "../../../assets/img/success.gif";

function SuccessDisplay({ message, subMessage, action, dontReload }) {
  return (
    <div className="success-display">
      <div className="message-content">
        <img src={SuccessGIF} alt="Success GIF" />
        <h5>{message}</h5>
        {subMessage && <p className={"subMessage"}>{subMessage}</p>}
        <button
          type="button"
          onClick={() => {
            if (action) {
              action();
              dontReload !== true && window?.location?.reload();
            } else window?.location?.reload();
          }}
        >
          Ok
        </button>
      </div>
    </div>
  );
}

export default SuccessDisplay;
