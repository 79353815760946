import axios from "axios";
import jwtDecode from "jwt-decode";
import dayjs from "dayjs";

export let apiToken = JSON.parse(localStorage.getItem("user_profile"));

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + apiToken?.accessToken,
  },
});

export const formApi = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + apiToken?.accessToken,
  },
});

export const invoiceAPI = (type) => {
  return axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL_INV,
    headers: {
      "Content-Type": type || "application/json",
      Authorization: "Bearer " + apiToken?.accessToken,
    },
  });
};





invoiceAPI()?.interceptors?.request?.use(
  async function (req) {
    if (!apiToken) {
      apiToken = localStorage.getItem("user_profile")
        ? JSON.parse(localStorage.getItem("user_profile"))
        : null;
      req.headers.Authorization = `Bearer ${apiToken?.accessToken}`;
    }

    // ////console.log("res", req);

    let user = jwtDecode(apiToken?.accessToken);
    let isExpired = dayjs.unix(user?.exp).diff(dayjs()) < 1;

    if (isExpired) {
      localStorage.removeItem("user_profile");
      localStorage.removeItem("user");
      window.location.replace("/");
    }

    return req;
  }
  // async function (error) {////console.log("errorMsg", error)}
);

// ////console.log(api)

api?.interceptors?.request?.use(
  async function (req) {
    if (!apiToken) {
      apiToken = localStorage.getItem("user_profile")
        ? JSON.parse(localStorage.getItem("user_profile"))
        : null;
      req.headers.Authorization = `Bearer ${apiToken?.accessToken}`;
    }

    // ////console.log("res", req);

    let user = jwtDecode(apiToken?.accessToken);
    let isExpired = dayjs.unix(user?.exp).diff(dayjs()) < 1;

    if (isExpired) {
      localStorage.removeItem("user_profile");
      localStorage.removeItem("user");
      window.location.replace("/");
    }

    return req;
  }
  // async function (error) {////console.log("errorMsg", error)}
);

export default api;
