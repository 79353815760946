import { FiBox, FiHome, FiPieChart, FiSettings, FiUser } from "react-icons/fi";
import { GetLocalStorageUser } from "../../../utils/GlobalUtils/GetLocalStorageValues";

const allResellerRoutes = ["Agent Management", "Performance"];

export const DrawerURLs = () => {
  // const userData = JSON.parse(localStorage.getItem("user_profile"));
  // const person = JSON.parse(localStorage.getItem("user"));
  // const hasAccount = person?.accountNumber;

  const userData = GetLocalStorageUser("user_profile");

  const URLValues = {
    Urls: [
      {
        icon: <FiHome />,
        name: "Dashboard",
        hasChildren: false,
        href: "/dashboard",
        isDashboard: true,
      },
      {
        icon: <FiHome />,
        name: "Wallets",
        hasChildren: false,
        href: "/dashboard/wallets",
      },
      {
        icon: <FiUser />,
        name: "Agent Management",
        hasChildren: true,
        href: "",
        children: [
          {
            name: "Agents",
            href: "/dashboard/agent-management/agents",
          },
        ],
      },
      // {
      //   icon: <BiBox />,
      //   name: "Funding",
      //   hasChildren: true,
      //   href: "",
      //   children: [
      //     {
      //       name: "Funding Customer Wallet",
      //       href: "/dashboard/funding/customer-wallet",
      //     },
      //   ],
      // },
      // {
      //   icon: <FiUserCheck />,
      //   name: "Debit Customer",
      //   hasChildren: false,
      //   href: "/dashboard/debit-customer-module",
      // },

      {
        icon: <FiBox />,
        name: "Products",
        hasChildren: true,
        href: "",
        children: [
          {
            name: "Airtime",
            href: "/dashboard/products/airtime",
          },
          {
            name: "Data",
            href: "/dashboard/products/data",
          },
          {
            name: "Electricity Bills",
            href: "/dashboard/products/electricity",
          },
          {
            name: "Cable TV",
            href: "/dashboard/products/cable-television",
          },
          {
            name: "Airtime Bulk",
            href: "/dashboard/products/bulk-airtime/bulk/payment",
          },
          // {
          //   name: "Data Bulk",
          //   href: "/dashboard/products/bulk-data/bulk/payment",
          // },
        ],
      },
      {
        icon: <FiBox />,
        name: "Agency Banking",
        hasChildren: true,
        href: "",
        children: [
          {
            name: "Fund Wallet",
            href: "/dashboard/agency-banking/fund",
          },
          {
            name: "Transfer",
            href: "/dashboard/agency-banking/transfer",
          },
          {
            name: "Withdraw Money",
            href: "/dashboard/agency-banking/wallet",
          },
        ],
      },
      {
        icon: <FiBox />,
        name: "Invoicing",
        hasChildren: true,
        href: "",
        children: [
          {
            name: "Open Invoice",
            href: "/dashboard/invoice/open",
          },
          {
            name: "Closed Invoice",
            href: "/dashboard/invoice/closed",
          },
        ],
      },
      {
        icon: <FiPieChart />,
        name: "History",
        hasChildren: true,
        href: "",
        children: [
          // {
          //   name: "Agent Listing",
          //   href: "/dashboard/report/agent-listing",
          // },
          // {
          //   name: "Transaction Listing",
          //   href: "/dashboard/report/transaction-listing",
          // },
          // {
          //   name: "Transaction Summary",
          //   href: "/dashboard/report/transaction-summary",
          // },
          // {
          //   name: "Commission Wallet",
          //   href: "/dashboard/report/commission-wallet",
          // },
          {
            name: "CashFlow",
            href: "/dashboard/report/main-wallet",
          },
          {
            name: "Airtime Report",
            href: "/dashboard/report/airtime-report",
          },
          {
            name: "Bills Report",
            href: "/dashboard/report/bills-report",
          },
          {
            name: "Data Report",
            href: "/dashboard/report/data-report",
          },
          {
            name: "Pending Transactions",
            href: "/dashboard/report/lien-transaction",
          },
        ],
      },
      {
        icon: <FiHome />,
        name: "Performance",
        hasChildren: false,
        href: "/dashboard/performance",
      },
      // {
      //   icon: <HiOutlineDocumentReport />,
      //   name: "Customer Reports",
      //   hasChildren: true,
      //   href: "",
      //   children: [
      //     {
      //       name: "WIPAY",
      //       href: "/dashboard/other-reports/wipay",
      //     },
      //   ],
      // },
      // {
      //   icon: <BiBox />,
      //   name: "Commission",
      //   hasChildren: false,
      //   href: "/dashboard/commissions",
      // },
      // {
      //   icon: <FiBox />,
      //   name: "Performance",
      //   hasChildren: false,
      //   href: "/dashboard/performance-management",
      // },
      {
        // for bank
        icon: <FiBox />,
        name: "Card Network",
        hasChildren: false,
        href: "/dashboard/card-network",
      },
      {
        icon: <FiSettings />,
        name: "Settings",
        hasChildren: true,
        href: "",
        children: [
          // {
          //   name: hasAccount ? "Update Settlement Bank" : "Add Settlement Bank",
          //   href: "/dashboard/settings/change-bank-account",
          // },
          {
            name: "Change PIN",
            href: "/dashboard/settings/change-pin",
          },
          {
            name: "Change Password",
            href: "/dashboard/settings/change-password",
          },
          {
            name: "Reset Transaction PIN",
            href: "/dashboard/settings/set-transaction-pin",
          },
        ],
      },
    ],
  };

  if (userData?.role !== "4") {
    const results = URLValues?.Urls?.filter((element) => {
      if (!allResellerRoutes.includes(element?.name)) {
        return true;
      }
      return false;
    });

    return { Urls: results };
  }

  if (userData?.role === "4") {
    const updatedURLValues = URLValues?.Urls?.filter(
      (x) => x?.name !== "Wallets"
    );
    return { Urls: updatedURLValues };
  }
  return URLValues;
};
