import { createContext, useState } from "react";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [open, closeModal] = useState(false);

  function closeAppModal() {
    closeModal(!open);
  }
  return (
    <AppContext.Provider value={{ open, closeAppModal }}>
      {children}
    </AppContext.Provider>
  );
};
