import { invoiceAPI } from "../api";


const InvoiceModule = () => {

  return {
    GetOpenInvoiceDetails: function (data) {
      return invoiceAPI().get(`invoice/${data}?with_items=true`);
    },
    GetOpenInvoiceTxn: function (data) {
      // const { startDate, endDate, keyword, id } = data;
      return invoiceAPI().get(
        `/payments/${data}?per_page=100000&keyword=
`
      );
    },

    GetInvoiceList: function (data) {
      const { start_date, end_date, keyword, status } = data;
      return invoiceAPI().get(
        `/invoices?payment_status=${status}&start_date=${start_date}&end_date=${end_date}&keyword=${keyword}`,
        data
      );
    },

    MakeInvoicePayment: function (data) {
      return invoiceAPI().post(`/invoice/pay`, data);
    },

    InvoiceNotification: function (data) {
      return invoiceAPI().get(`/notifications`, data);
    },

    MarkInvoiceNotification: function (data) {
      return invoiceAPI().put(`/mark-notication-as-read`, data);
    },
  };
};

export const InvoiceService = {
  invoiceModule: InvoiceModule,
};
