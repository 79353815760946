import axios from "axios";
import api from "../api";

const authApi = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

const AuthModule = () => {
  return {
    Login: function (data) {
      return authApi.post("identity/Agent/login", data);
    },

    ResetPassword: function (data) {
      // return authApi.post("identity/Agent/setnewpassword", data);
      return authApi.post("identity/Agent/completeforgotpassword", data);
    },

    CurrentUser: function (data) {
      return api.get("identity/Agent/sessiondetails");
    },

    LinenTransaction: function (data) {
      const { startDate, endDate, currentPage, pageSize, reference } = data;
      return api.get(`wallet/Transaction/liens?Reference=${reference}&PageSize=${pageSize}&CurrentPage=${currentPage}&StartDate=${startDate}&EndDate=${endDate}`);
    },

    ServiceToday: function (data) {
      return api.get(`wallet/Wallet/report/service/today`);
    },

    ServiceWeek: function (data) {
      return api.get("wallet/Wallet/report/service/this_week");
    },

    AgentDetails: function (data) {
      const { page, limit } = data;
      return api.get(
        `/transactions/report/superdealer/performance/top-resselers?customer_id=501&service_codes=100,101,102,103,106,105&per_page=${limit}&page=${page}`
      );
    },
  };
};

export const AuthService = {
  auth: AuthModule,
};
